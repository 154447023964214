<template>
  <b-card-actions ref="card" title="" action-refresh @refresh="refresh">
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="HomeIcon"/>
          <span>  {{ $t('Profile') }}
</span>
        </template>
        <div>
          <!-- Media -->
          <b-media class="mb-2">
            <template #aside>
              <cropper
                  id="profile_image"
                  ref="cropper"
                  :src="company.logo"
                  class="cropper h-24 w-24"
                  v-if="showCropper"
                  :stencil-props="{
	}"
              />
              <img v-else :src="company.logo" class="mr-8 rounded h-24 w-24 profile-pic"/>
            </template>
            <h4 class="mb-1">
              {{ company.name }}
            </h4>
            <div class="d-flex flex-wrap" v-if="is_admin">

              <input
                  type="file"
                  ref="refInputEl"
                  class="hidden"
                  accept="image/*"
                  name="profile_image"
                  id="profile_image"
                  @change="selectedFile"
              />

              <b-button
                  variant="primary"
                  @click="$refs.refInputEl.click()"
                  v-if="!showCropper "
                  :disabled="!is_admin"


              >

                <span class="d-none d-sm-inline">{{ $t('Upload image') }}</span>
                <feather-icon
                    icon="EditIcon"
                    class="d-inline d-sm-none"
                />
              </b-button>
              <b-button
                  variant="primary"
                  @click="cropImage()"
                  v-else
              >

                <span class="d-none d-sm-inline">{{ $t('Crop') }}</span>
                <feather-icon
                    icon="EditIcon"
                    class="d-inline d-sm-none"
                />
              </b-button>
              <b-button
                  :disabled="!is_admin"
                  v-if="is_admin"
                  variant="outline-secondary"
                  class="ml-1"
                  @click="deleteImage()"
              >
                <span class="d-none d-sm-inline">{{ $t('Delete') }}</span>
                <feather-icon
                    icon="TrashIcon"
                    class="d-inline d-sm-none"

                />
              </b-button>
            </div>
          </b-media>
          <validation-observer
              ref="validationRules"
              tag="form"
          >
            <!-- User Info: Input Fields -->
            <b-form>
              <b-row>

                <!-- Field: Name -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      :label="$t('Name')"
                      label-for="company_name"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="company_name"
                        rules="required"
                        :custom-messages="customMessages"
                        :state="errors.length > 0 ? false:null"

                    >
                      <b-form-input
                          :disabled="!is_admin"
                          id="company_name"
                          v-model="company.name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>

                  </b-form-group>
                </b-col>


                <!-- Field: Vat Number -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      :label="$t('Vat Number')"
                      label-for="vat_number"

                  >
                    <b-form-input
                        id="vat_number"
                        v-model="company.vat_number"
                        :disabled="!is_admin"

                    />
                  </b-form-group>
                </b-col>


              </b-row>
              <b-row>

                <!-- Field: activity -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      :label="$t('Activity')"
                      label-for="activity"
                      :disabled="allEditDisabled"

                  >
                    <b-form-input
                        id="activity"
                        v-model="company.activity"
                        :disabled="allEditDisabled"

                    />
                  </b-form-group>
                </b-col>

                 <!-- Field: ateco -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      :label="$t('Ateco Sector')"
                      label-for="ateco"
                      :disabled="allEditDisabled"

                  >
                    <b-form-input
                        id="ateco"
                        v-model="company.ateco"
                        :disabled="allEditDisabled"

                    />
                  </b-form-group>
                </b-col>

              </b-row>
              <b-row>

                <!-- Field: number_of_employees -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      :label="$t('Number of employees')"
                      label-for="number_of_employees"
                      :disabled="allEditDisabled"

                  >
                    <b-form-input
                        id="number_of_employees"
                        v-model="company.number_of_employees"
                        :disabled="allEditDisabled"
                        type="number"

                    />
                  </b-form-group>
                </b-col>

                <!-- Field: ateco -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      :label="$t('Risk Class')"
                      label-for="risk_class"
                      :disabled="allEditDisabled"

                  >

                    <b-dropdown
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        :text="currentRiskClass"
                        variant="outline-primary"
                        block
                        :disabled="allEditDisabled"
                    >
                      <b-dropdown-item
                          v-for="risk_class in risk_classes"
                          :key="risk_class"
                          @click="setRisk(risk_class)"
                      >
                        <span class="ml-50">{{ $t( risk_class) }}</span>
                      </b-dropdown-item>

                    </b-dropdown>

                  </b-form-group>
                </b-col>

              </b-row>
              <b-row>
                <!-- Field: Representative -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      :label="$t('Representative')"
                      label-for="representative"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="representative"
                        rules="required"
                        :custom-messages="customMessages"
                        :state="errors.length > 0 ? false:null"

                    >
                      <b-form-input
                          :disabled="allEditDisabled"
                          id="representative"
                          v-model="company.representative"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>

                  </b-form-group>
                </b-col>


                <!-- Field: Vat Number -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      :label="$t('Email (1)')"
                      label-for="email1"
                      :disabled="allEditDisabled"

                  >
                    <b-form-input
                        id="email1"
                        v-model="company.email1"
                        :disabled="allEditDisabled"

                    />
                  </b-form-group>
                </b-col>


              </b-row>
              <b-row>

                <!-- Field: Email 2 -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      :label="$t('Email (2)')"
                      label-for="email2"
                  >

                    <b-form-input
                        :disabled="allEditDisabled"
                        id="email2"
                        v-model="company.email2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>

                  </b-form-group>
                </b-col>


                <!-- Field: Vat Number -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      :label="$t('Email (3)')"
                      label-for="email3"
                      :disabled="allEditDisabled"

                  >
                    <b-form-input
                        id="email1"
                        v-model="company.email3"
                        :disabled="allEditDisabled"

                    />
                  </b-form-group>
                </b-col>


              </b-row>


              <b-row>
                <!-- Field: Address -->
                <b-col
                    cols="12"
                    md="12"
                >
                  <b-form-group
                      :label="$t('Legal Address')"
                      label-for="address"
                  >
                    <b-form-textarea
                        rows="3"
                        id="address"
                        name="address"
                        v-model="company.address"
                        :disabled="!is_admin"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Address -->
                <b-col
                    cols="12"
                    md="12"
                >
                  <b-form-group
                      :label="$t('Operative Address')"
                      label-for="address"
                  >
                    <b-form-textarea
                        rows="3"
                        id="address"
                        name="address"
                        v-model="company.operative_address"

                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>

                <!-- Field: RSPP -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      :label="$t('RSPP')"
                      label-for="RSPP"
                  >

                    <b-form-input
                        :disabled="allEditDisabled"
                        id="RSPP"
                        v-model="company.RSPP"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>

                  </b-form-group>
                </b-col>


                <!-- Field: RSPP_nominated_on -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      :label="$t('Nominated on')"
                      label-for="RSPP_nominated_on"
                      :disabled="allEditDisabled"

                  >
                    <flat-pickr
                        id="RSPP_nominated_on"
                        v-model="company.RSPP_nominated_on"
                        class="form-control"
                        :disabled="allEditDisabled"
                        :config="flatPickrConfig"
                    />

                  </b-form-group>
                </b-col>


              </b-row>

              <b-row>

                <!-- Field: RLS -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      :label="$t('RLS')"
                      label-for="RLS"
                  >

                    <b-form-input
                        :disabled="allEditDisabled"
                        id="RLS"
                        v-model="company.RLS"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>

                  </b-form-group>
                </b-col>


                <!-- Field: RSPP_nominated_on -->
                <b-col
                    cols="12"
                    md="3"
                >
                  <b-form-group
                      :label="$t('RLS elected on')"
                      label-for="RLS_elected_on"
                      :disabled="allEditDisabled"

                  >
                    <flat-pickr
                        id="RLS_elected_on"
                        v-model="company.RLS_elected_on"
                        class="form-control"
                        :disabled="allEditDisabled"
                        :config="flatPickrConfig"
                    />

                  </b-form-group>
                </b-col>

                <!-- Field: RSPP_nominated_on -->
                <b-col
                    cols="12"
                    md="3"
                >
                  <b-form-group
                      :label="$t('Communication on')"
                      label-for="INAIL_communication_on"
                      :disabled="allEditDisabled"

                  >
                    <flat-pickr
                        id="INAIL_communication_on"
                        v-model="company.INAIL_communication_on"
                        class="form-control"
                        :disabled="allEditDisabled"
                        :config="flatPickrConfig"
                    />

                  </b-form-group>
                </b-col>


              </b-row>


              <b-row>

                <!-- Field: Doctor -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      :label="$t('Doctor')"
                      label-for="doctor"
                  >

                    <b-form-input
                        :disabled="allEditDisabled"
                        id="doctor"
                        v-model="company.doctor"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>

                  </b-form-group>
                </b-col>


                <!-- Field: doctor_nominated_on -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      :label="$t('Nominated on')"
                      label-for="doctor_nominated_on"
                      :disabled="allEditDisabled"

                  >
                    <flat-pickr
                        id="RSPP_nominated_on"
                        v-model="company.RSPP_nominated_on"
                        class="form-control"
                        :disabled="allEditDisabled"
                        :config="flatPickrConfig"
                    />

                  </b-form-group>
                </b-col>


              </b-row>
              <b-row>

                <!-- Field: RSPP basic -->
                <b-col
                    cols="12"
                    md="1"
                >
                  <b-form-group
                      :label="$t('RSPP Basic')"
                      label-for="RSPP_basic"
                  >

                    <b-form-checkbox
                        :disabled="allEditDisabled"
                        id="RSPP_basic"
                        v-model="company.RSPP_basic"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>

                  </b-form-group>
                </b-col>

                <!-- Field: SPP_expiration -->
                <b-col
                    cols="12"
                    md="2"
                >
                  <b-form-group
                      :label="$t('Expiration SPP')"
                      label-for="SPP_expiration"
                      :disabled="allEditDisabled"

                  >
                    <flat-pickr
                        id="SPP_expiration"
                        v-model="company.SPP_expiration"
                        class="form-control"
                        :disabled="allEditDisabled"
                        :config="flatPickrConfig"
                    />

                  </b-form-group>
                </b-col>

                <!-- Field: vibration_evaluation_expiration -->
                <b-col
                    cols="12"
                    md="2"
                >
                  <b-form-group
                      :label="$t('Vibration Evaluation Expiration')"
                      label-for="vibration_evaluation_expiration"
                      :disabled="allEditDisabled"

                  >
                    <flat-pickr
                        id="vibration_evaluation_expiration"
                        v-model="company.vibration_evaluation_expiration"
                        class="form-control"
                        :disabled="allEditDisabled"
                        :config="flatPickrConfig"
                    />

                  </b-form-group>
                </b-col>

                <!-- Field: noise_evaluation_expiration -->
                <b-col
                    cols="12"
                    md="2"
                >
                  <b-form-group
                      :label="$t('Noise Evaluation Expiration')"
                      label-for="noise_evaluation_expiration"
                      :disabled="allEditDisabled"

                  >
                    <flat-pickr
                        id="noise_evaluation_expiration"
                        v-model="company.noise_evaluation_expiration"
                        class="form-control"
                        :disabled="allEditDisabled"
                        :config="flatPickrConfig"
                    />

                  </b-form-group>
                </b-col>


                <!-- Field: stress_evaluation_expiration -->
                <b-col
                    cols="12"
                    md="2"
                >
                  <b-form-group
                      :label="$t('Stress evaluation expiration')"
                      label-for="stress_evaluation_expiration"
                      :disabled="allEditDisabled"

                  >
                    <flat-pickr
                        id="stress_evaluation_expiration"
                        v-model="company.stress_evaluation_expiration"
                        class="form-control"
                        :disabled="allEditDisabled"
                        :config="flatPickrConfig"
                    />

                  </b-form-group>
                </b-col>


              </b-row>
            </b-form>
          </validation-observer>


          <!-- PERMISSION TABLE -->
          <b-card
              no-body
              class="border mt-1"
              v-if="is_admin"
          >
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon
                    icon="LockIcon"
                    size="18"
                />
                <span class="align-middle ml-50"> {{ $t('Permissions') }}</span>
              </b-card-title>
            </b-card-header>
            <b-table
                striped
                responsive
                class="mb-0"
                ref="permissionTable"
                :fields="permission_fields"
                :items="permissionsData"
            >
              <template #cell(name)="row">
                {{ row.item.name }}
              </template>
              <template #cell(read)="row">
                <b-form-checkbox :disabled="editDisabled || row.item.myself ||allEditDisabled"
                                 v-model="row.item.read"></b-form-checkbox>
              </template>
              <template #cell(write)="row">
                <b-form-checkbox :disabled="editDisabled|| row.item.myself || allEditDisabled || (!row.item.read)"
                                 v-model="row.item.write"></b-form-checkbox>
              </template>


            </b-table>
          </b-card>


          <!-- Action Buttons -->
          <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click.prevent.stop="handleSubmit()"

          >
            {{ $t('Save') }}
          </b-button>
          <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click.prevent.stop="back($event)"
          >
            {{ $t('Cancel') }}


          </b-button>
        </div>
      </b-tab>
      <b-tab :disabled="users_disabled">
        <template #title>
          <feather-icon icon="BookIcon"/>
          <span>            {{ $t('Users') }}</span>
        </template>
        <users-list-component/>

      </b-tab>
    </b-tabs>

  </b-card-actions>


</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BCardText,
  BButton,
  BCard,
  BMedia,
  BAvatar,
  BFormTextarea,
  BTable,
  BTabs,
  BTab,
  BCardHeader,
  BDropdown,
  BDropdownItem,

  BCardTitle, BFormFile,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ability from "@/libs/acl/ability";
import Ripple from 'vue-ripple-directive'
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store";
import {codeI18n} from "@/views/table/vue-good-table/code";
import {useRouter} from "@core/utils/utils";
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {BCardActions} from "@core/components/b-card-actions";
import i18n from '@/libs/i18n'
import Spacer from "@/views/components/spacer/Spacer";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import EmployeeService from "@/basic/employeeService";
import useUsersList from "@/views/apps/user/users-list/useUsersList";
import {ref} from "@vue/composition-api";
import {avatarText} from "@core/utils/filter";
import vSelect from 'vue-select'
import CoursesListComponent from "@/views/table/vue-good-table/CoursesListComponent";
import MedicalCheckupsListComponent from "@/views/table/vue-good-table/MedicalCheckupsListComponent";
import UserService from "@/basic/userService";
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import UsersListComponent from "@/views/table/vue-good-table/UsersListComponent";
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import {environment} from "@/auth/environment";


export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BRow,
    BCol,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BAvatar,
    BCard,
    BFormTextarea,
    BTable,
    BCardHeader,
    BCardTitle,
    BCardActions,
    BMedia,
    BTabs,
    BDropdown,
    BTab,
    BDropdownItem,
    flatPickr,
    Spacer,
    vSelect,
    CoursesListComponent,
    MedicalCheckupsListComponent,
    BFormFile,
    Cropper,
    UsersListComponent,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      company: {
        name: "",
        logo: require("@/assets/images/avatars/teamwork.png"),
        address: "",
        vat_number: ""

      },
      customMessages: {
        required: i18n.t('This field is required'),
      },
      errors: [],
      company_modules: [],

      permissionsData: [],
      risk_classes: ['low','medium','high'],
      permission_fields: [{key: 'name', label: i18n.t('Name'),}, {key: 'read', label: i18n.t('Read'),}, {
        key: 'write',
        label: i18n.t('Write')
      }],

      variant: "light",
      opacity: 0.85,
      blur: "2px",
      crop: {
        src: "",
        type: "image/jpg",
      },
      file_name: "",
      showCropper: false,
      userData: JSON.parse(localStorage.getItem('userData')),
      userCompany: JSON.parse(localStorage.getItem('userCompany')),
      editDisabled: false,
      allEditDisabled: !ability.can("manage", "Basic Admin"),
      users_disabled: true,
      is_admin: ability.can("manage", "admin"),
      flatPickrConfig: {
        dateFormat: 'd/m/Y',
      },
    }
  },
  computed: {
    currentRiskClass() {
      return i18n.t(this.company.risk_class)
    },
  },
  methods: {
    setRisk(risk_class) {
      console.log('risk_class',risk_class)
      this.company.risk_class = risk_class
    },
    refresh() {

      const userService = new UserService(useJwt)

      let company_id = this.$route.params.id;
      this.$refs["card"].showLoading = true


      userService.company(company_id).then(response => {
        if (company_id !== 'new') {
          this.company = response.data.data.company;
          this.users_disabled = false
        }
        this.company_modules = response.data.data.company_modules;


        this.permissionsData = this.company_modules.map(function (value, index) {
          return {
            id: value.id,
            name: value.module.name,
            read: value.read === 1,
            write: value.write === 1,
            module_id: value.module.id,
            myself: false
          }
        })

        for (let permissionDatum of this.permissionsData) {
          if (permissionDatum.id === this.userCompany.id) {
            if (this.userData.role !== 'admin') {
              this.editDisabled = (!permissionDatum.write);
            }
            permissionDatum.myself = true;
          }

        }
        console.log('permissionsData', this.permissionsData)

        this.$refs["card"].showLoading = false


      })


    },
    async back(e) {
      this.$router.push({name: 'companies'})

    },

    selectedFile(event) {
      this.success = "";
      this.error = "";
      var filePath = event.target.files[0].name;
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(filePath)) {
        this.error = this.$t("FileUploadError");
        this.$toasted.show(this.error, {
          className: "top-alert",
          position: "top-center",
          type: "error",
          duration: 2000,
        });
        $("#profile_image").val("");
      } else {
        this.images = event.target.files[0];
        this.file_name = this.images.name;
        this.company.logo = URL.createObjectURL(this.images);
        this.crop.src = URL.createObjectURL(this.images);
        this.showCropper = true;
      }
    },

    handleUpload() {
      console.log("UPLOAD 1")


      console.log("UPLOAD 2")

      let filevalue = this.avatar_file;
      console.log('handleUpload filevalue', filevalue)

      var filePath = filevalue.name;
      console.log('handleUpload filePath', filevalue.name)


      let reader = new FileReader();

      reader.onload = (e) => {
        console.log('readAsDataURL', e.target.result);
        this.filedata = e.target.result
        const data_upload = {
          filedata: this.filedata,
          filename: filePath
        };
        console.log("UPLOAD 2", filedata)

        this.company.photo = filedata;

      };

      reader.readAsDataURL(this.avatar_file);


    },

    cropImage() {
      const result = this.$refs.cropper.getResult();
      let obj = this;
      this.company.logo = result.canvas.toDataURL(this.crop.type);
      console.log('url', this.company.logo)
      this.urltoFile(
          result.canvas.toDataURL(this.crop.type),
          this.file_name,
          "image/*"
      ).then(function (file) {
        console.log("rahul--", file);
        obj.images = file;

      });
      this.images = obj.images;
      this.showCropper = false;
      console.log("rahul--2", this.images);

    },

    deleteImage() {
      console.log('deleteImage')
      this.images = "";
      this.showCropper = false;
      this.company.logo = require("@/assets/images/avatars/teamwork.png");


    },

    urltoFile(url, filename, mimeType) {
      return fetch(url)
          .then(function (res) {
            return res.arrayBuffer();
          })
          .then(function (buf) {
            return new File([buf], filename, {type: mimeType});
          });
    },
    setDepartment(department) {
      this.department_new_id = department.id;
    },
    download_white() {
      let company_id = this.$route.params.id;

      const link = document.createElement("a");
      link.href = environment.baseURL + "/dashboard/downloadEmployerDeclaration/" + company_id + "/0"
      link.download = this.company.name + "_declaration.pdf";
      link.click();
      URL.revokeObjectURL(link.href);

    },

    download_filled() {
      let company_id = this.$route.params.id;

      const link = document.createElement("a");
      link.href = environment.baseURL + "/dashboard/downloadEmployerDeclaration/" + company_id + "/1"
      link.download = this.company.name + "_declaration.pdf";
      link.click();
      URL.revokeObjectURL(link.href);

    },


    async handleSubmit(e) {
      this.$refs.validationRules.validate().then(success => {
            if (success) {
              console.log("VALIDATION OK")

              console.log("inputImageRenderer", this.inputImageRenderer)
              this.submitted = true;

              this.$refs["card"].showLoading = true

              const data = {
                company: this.company,
                permissions: this.permissionsData
              };


              try {
                console.log('data', data)
                let company_id = this.$route.params.id;
                const userService = new UserService(useJwt)

                userService.update_company(company_id, data).then(response => {
                  console.log("response", response)
//          this.refresh();
                  this.$refs["card"].showLoading = false
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: i18n.t('Company'),
                      icon: 'AlertIcon',
                      variant: 'success',
                      text: i18n.t('Company saved with success'),
                    },
                  })
                  this.$router.push({name: 'companies'})
                })

                //const response = await createUpdateUser(data);
                const response = {status: 200, data: {id: 1}}
                /*this.$vs.notify({
                  title: this.$t("Success"),
                  text: this.user_id ? this.$t('UserUpdatedSuccessfull') : this.$t('UserCreatedSucessfull'),
                  color: "success",
                  position: "top-center"
                });if (response.status === 200 && response.data.id)
                  this.$router.push({name: 'ViewUser', params: {id: response.data.id}});

        */

              } catch (error) {

              }
              this.submitted = true;
            } else {
              console.log("VALIDATION KO")

              this.submitted = false;

            }
          }
      )
    },

  },


  mounted() {
    this.refresh()
    //this.$http.get('/good-table/basic')
    //.then(res => { this.rows = res.data })
  },
  setup(props) {
    const {resolveUserRoleVariant} = useUsersList()

    const refInputEl = ref(null)
    const previewEl = ref(null)

    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.avatar = base64
      console.log("avatar", props.avatar)
    })

    return {
      resolveUserRoleVariant,
      avatarText,

      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
